import { InputNumber, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { apiSecure } from "../../../components/api";
import { getParsedDate } from "../../../components/globalFunctions";
import { Loading } from "../../../components/Loading/Loading";

const { Option } = Select;

export const Screen2Edit = ({
  setHeaderText,
  closeModal,
  tables,
  data,
  selectedWork,
  SaveBtn,
  DeleteBtn
}) => {
  console.log("screen2edit data", data, selectedWork);

  const [isLoading, setisLoading] = useState(false);

  const [rowId, setRowId] = useState(null);

  const [oldLastWork, setOldLastWork] = useState(null);
  const [selectedLastWork, setSelectedLastWork] = useState();

  const [startDate, setStartDate] = useState(moment());

  const [maxPercent, setMaxPercent] = useState(null);
  const [workPercent, setWorkPercent] = useState(null);
  const [workCount, setWorkCount] = useState(null);

  const [workDeciPercent, setWorkDeciPercent] = useState(null)

  const [multiplier, setMultiplier] = useState(1.0);
  const [table, setTable] = useState(null);

  const [calculatedEndDate, setCalculatedEndDate] = useState(moment());

  const [verifyDeleteOpen, setVerifyDeleteOpen] = useState(false);

  //Päivitetään kuormitus
  const saveData1 = () => {
    if (!setSelectedLastWork) return alert("Valitse edellinen työ");
    if (!startDate) return alert("Aloituspäivämäärä laskennassa virhe");
    if (!workPercent) return alert("Valitse osuus");
    if (!multiplier) return alert("Valitse kerroin");
    if (!table) return alert("Valitse tuotantopaikka");

    setisLoading(true);

    let req_config = {
      method: "POST",
      url: `/intra_updateWork`,
      data: {
        rowId: rowId,
        project: selectedWork.Tyokohdenro,
        elementGroup: selectedWork.ElementtiRyhmaNro,
        earlierWork: selectedLastWork,
        oldEarlierWork: oldLastWork,
        worker: data.userId,
        startDate: startDate,
        workPercent: workPercent / 100,
        multiplier: multiplier,
        table: table,
      },
    };



    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        //setUsersData(response.data.data);
        setisLoading(false);
        closeModal(true)
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  const deleteWork = () => {

    let req_config = {
      method: "POST",
      url: `/intra_removeWork`,
      data: {
        rowId: rowId,
        earlierWork: data.lastWork
      },
    };

    console.log('deleteWork', req_config)


    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        setisLoading(false);
        closeModal(true)
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });

  };

  //Avaus laskenta
  useEffect(() => {
    console.log("screen2 data", data);

    if (data) {
      if (data.lastWork) {
        let lastworkFound = data.userWorks.find(
          (row) => row.KirjausId === data.lastWork
        );

        console.log("lastwork", data.lastWork, lastworkFound);

        if (lastworkFound) {
          let startdate = moment(lastworkFound.endDate).add(1, "day");

          setOldLastWork(data.lastWork);
          setSelectedLastWork(data.lastWork);
          setStartDate(startdate);
        }
      }

      setRowId(data.rowId);
      setMultiplier(data.multiplier);
      setTable(data.table);

      //Jo kuormitetut prosentit
      let currpercent = data.percent * 100;
      setWorkPercent(currpercent);
      setWorkDeciPercent(data.percent)
      console.log("log2 currpercent", currpercent);

      //Lasketaan maksimiprosentti
      if (selectedWork) {
        console.log("log2 selectedWork", selectedWork);

        let KuormittamattaKpl = selectedWork.KuormittamattaKpl;
        let ValmistamattaKpl = selectedWork.ValmistamattaKpl;

        console.log("log2 KuormittamattaKpl", KuormittamattaKpl);
        console.log("log2 ValmistamattaKpl", ValmistamattaKpl);

        if (ValmistamattaKpl > 0) {
          console.log("log2 laskenta");

          let maxpercent = (
            (KuormittamattaKpl / ValmistamattaKpl) *
            100
          ).toFixed(0);
          maxpercent = parseInt(maxpercent) + currpercent;

          console.log("log2 maxpercent", maxpercent);
          setMaxPercent(maxpercent);
          /*  setWorkPercentpercent) */
        }
      }
    }
  }, [data]);


  const calculateDays = (valmistamattaLH) => {
    let hnkKerroin = multiplier
    let kuormitusOsuus = workPercent / 100;

    console.log(
      "Aloitetaan laskentaa 2:",
      kuormitusOsuus,
      hnkKerroin,
      valmistamattaLH
    );

    /*     let remainingDays = (((valmistamattaLH / 8) * kuormitusOsuus) / hnkKerroin +
        (0.05 * valmistamattaLH * kuormitusOsuus) / hnkKerroin) */

    let remainingDays = ((valmistamattaLH / 8) * kuormitusOsuus) / hnkKerroin


    console.log("Laskennan tulos2:", remainingDays);
    return remainingDays
  }

  useEffect(() => {
    if (selectedWork) {
      let ValmistamattaKpl = selectedWork.ValmistamattaKpl;

      if (ValmistamattaKpl) {
        let count = ((workPercent / 100) * ValmistamattaKpl).toFixed(2); //  (KuormittamattaKpl/ValmistamattaKpl * 100).toFixed(0)
        console.log("count", workPercent, ValmistamattaKpl, count);
        //setMaxPercent(percent)
        setWorkCount(count);
      }
    }

    let valmistamattaLH = selectedWork.ValmistamattaLaskennallisestiH;

    if (workPercent && multiplier && valmistamattaLH && startDate) {
      let dayss = calculateDays(valmistamattaLH)


      //jaetaan työpäivien määrällä
      dayss = dayss / 5

      //Kerrotaan viikolla, saadaan oikea määrä "työpäiviä"
      dayss = dayss * 7

      let calc = moment(startDate).add(dayss, "days");
      setCalculatedEndDate(calc);
    }
  }, [workPercent, multiplier, startDate]);




  const calcDaily = (percent, target, days) => {
    // console.log("daily:_", percent, target, days)

    let dailyAmount = target / days
    let result = (percent * dailyAmount).toFixed(1)

    // console.log("daily2", result)

    return result

  }


  useEffect(() => {
    setHeaderText(verifyDeleteOpen ? "Tuotannon poisto" : 'Muokkaa tuotantoa')
  }, [setHeaderText, verifyDeleteOpen])

  if (isLoading) {
    return <Loading />;
  }




  return (
    <>

      {verifyDeleteOpen && <>


        <p
          className="font-dm-bold"
          style={{
            marginTop: 10,
            fontSize: 20
          }}
        >
          Tahdotko poistaa työkohteen: {selectedWork.Tyokohde}
        </p>



        <DeleteBtn onClick={() => setVerifyDeleteOpen(false)} text={'Peruuta'} />
        <SaveBtn onClick={deleteWork} text={'Kyllä'} />
      </>}


      {!verifyDeleteOpen && <>




        <div className="screen2Cont">
          <div>
            <p className="header1 font-dm-bold">{selectedWork.Tyokohde}</p>
            <div className="tyokohdeRow">

              <p className="header2 font-dm-medium">
                {selectedWork.ElementtiRyhma +
                  " " +
                    (selectedWork.ValmistamattaKpl
                      ? selectedWork.ValmistamattaKpl
                      : "0") +
                    " kpl, "+(selectedWork.KuormittamattaKpl
                      ? selectedWork.KuormittamattaKpl
                      : "0") +
                    " kpl kuormittamatta"
                    }
              </p>
            </div>
          </div>

          {data.jobEnding && (
            <p
              className="font-dm-bold"
              style={{
                color: "red",
              }}
            >
              Huom. Työsuhde loppuu: {getParsedDate(data.jobEnding)}{" "}
            </p>
          )}

          <div className="screen2Prod">
            <p className="header1 font-dm-bold">Tuotanto</p>

            <ul className="events">
              <li>
                <ball />
                <div className="item line">
                  <p className="font-dm-medium">Edellinen työ</p>
                  <div className="item-right">
                    {/* data.userWorks */}

                    <Select
                      defaultValue="0"
                      style={{ width: 150 }}
                      className="picker value"
                      value={selectedLastWork}
                      onChange={(value, option) => {
                        console.log("PICKER CHANGE", value);

                        //let selected = data.userWorks[value - 1];

                        let selected = data.userWorks.find(
                          (row) => row.KirjausId === value
                        );

                        console.log("selected", selected);

                        if (selected) {
                          console.log("update selected");
                          setSelectedLastWork(selected.KirjausId);
                          let startdate = moment(selected.endDate).add(1, "day");
                          setStartDate(startdate);
                        } else {
                          setSelectedLastWork('0')
                          //setStartDate(null);
                          setStartDate(moment());
                        }
                      }}
                    >

                      {!selectedLastWork &&
                        <Option value="0">Valitse...</Option>}

                      {data.userWorks.map((row, index) => {
                        return (
                          <Option key={index + 1} value={row.KirjausId}>
                            {row.name}
                          </Option>
                        );
                      })}
                    </Select>

                    {/*   <p className="font-dm-medium">Kemi metsä fibre</p> */}
                  </div>
                </div>
              </li>

              <li>
                <ball />
                <div className="item line">
                  <p className="font-dm-medium">Arvioitu aloituspäivämäärä</p>
                  <div className="item-right">
                    {/* <p className="font-dm-medium">20.9.2022</p> */}
                    <p className="font-dm-medium">
                      {startDate ? getParsedDate(startDate) : "Ei valittu"}
                    </p>
                  </div>
                </div>
              </li>

              <li>
                <ball />
                <div className="item line">
                <p className="font-dm-medium">Osuus valmistuksesta ({workCount} kpl)</p>
                  <div className="item-right">
                    {/* <p className="font-dm-medium">100%</p> */}
                    {maxPercent && (
                      <InputNumber
                        style={{ width: 70 }}
                        defaultValue={workPercent}
                        min={0}
                        max={maxPercent}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value.replace("%", "")}
                        onChange={(value) => {
                          console.log("change value", value);
                          setWorkPercent(value);
                          setWorkDeciPercent((value / 100).toFixed(2))
                        }}
                      />
                    )}
                  </div>
                </div>
              </li>

              <li>
                <ball />
                <div className="item line">
                  <p className="font-dm-medium">Henkilökohtainen kerroin</p>
                  <div className="item-right">
                    {/* <p className="font-dm-medium">1,0</p> */}
                    <InputNumber
                      style={{ width: 70 }}
                      defaultValue={1.0}
                      min={0.5}
                      max={5.0}
                      step={0.1}
                      value={multiplier}
                      onChange={(value) => {
                        console.log("change value", value);
                        setMultiplier(value);
                      }}
                    />
                  </div>
                </div>
              </li>

              <li>
                <ball />
                <div className="item line">
                  <p className="font-dm-medium">Tuotantopaikka</p>
                  <div className="item-right">
                    <Select
                      defaultValue="0"
                      style={{ width: 150 }}
                      className="picker value"
                      value={table}
                      onChange={(value, option) => {
                        //let selected = tables[value - 1];
                        //console.log("selected", selected);

                        let selected = tables.find(
                          (row) => row.PoytaNro === value
                        );

                        if (selected) {
                          setTable(selected.PoytaNro);
                        } else {
                          setTable(null);
                        }
                      }}
                    >
                      <Option value="0">Valitse...</Option>
                      {tables.map((row, index) => {
                        /*                       console.log('tables', index+1, row)
                         */
                        return (
                          <Option key={index + 1} value={row.PoytaNro}>
                            {row.Poyta}
                          </Option>
                        );
                      })}
                    </Select>

                    {/*  <p className="font-dm-medium">1. Elementtipöytä</p> */}
                  </div>
                </div>
              </li>

              <li>
                <ball className="last" />
                <div className="item">
                  <p className="font-dm-medium">
                    {"Arvioitu valmistuminen " + getParsedDate(calculatedEndDate)}

                    {/* laskenta: dbo.AikajanaTulevaTuotanto.ValmistamattaLaskennallisestiH / 8 * dbo.AikajanaMenkinta.KuormitusOsuus / dbo.AikajanaMenkinta.HenkilokohtainenKerroin + 0,05 * dbo.AikajanaTulevaTuotanto.ValmistamattaLaskennallisestiH * dbo.AikajanaMenkinta.KuormitusOsuus / dbo.AikajanaMenkinta.HenkilokohtainenKerroin */}
                  </p>

                </div>
              </li>
              <div className="kestoDiv">
                <p className="font-dm-medium">
                  Tuotannon kesto {calculateDays(selectedWork.ValmistamattaLaskennallisestiH).toFixed(2)} työpäivää
                </p>
              </div>
            </ul>



            <div className="tyoaikaContainer">
              <p className="tyoaikaHeader">Työaika</p>

              <div className="tyoaikaRow">
                <p className="tyoaikaTitle">Tarjouslaskenta</p>
                <p className="tyoaikaText">
                {selectedWork.LaskennanTyoaikaYksikolle} <span className="tyoaikaSmallText">h/{!selectedWork.jmTuote ? "m2" : "jm"}</span>  
                </p>
              </div>

              <div className="tyoaikaRow">
                <p className="tyoaikaTitle">Kuormituksessa</p>
                <p className="tyoaikaText">
                      {selectedWork.LaskennanTyoaikaYksikolle ? (selectedWork.LaskennanTyoaikaYksikolle / multiplier).toFixed(1) : '-'} <span className="tyoaikaSmallText">h/{!selectedWork.jmTuote ? "m2" : "jm"}</span>  
                </p>
              </div>
            </div>


            <div className="yhteenvetoContainer">
              <p className="yhteenvetoHeader">Valmistuksen yhteenveto</p>
              <div className="yhteenvetoRow">
                <p className="yhteenvetoTitle">Päivässä</p>
                <p className="yhteenvetoText">
                  {/* {(data.percent * (selectedWork.ValmistamattaMaara / calculateDays(selectedWork.ValmistamattaLaskennallisestiH)).toFixed(1))} */}
                  {calcDaily(workDeciPercent, selectedWork.ValmistamattaMaara, calculateDays(selectedWork.ValmistamattaLaskennallisestiH))}
                  <span className="yhteenvetoSmallText">{selectedWork.jmTuote ? "jm" : "m2"}</span>
                </p>

                <p className="yhteenvetoText">
                  {calcDaily(workDeciPercent, selectedWork.ValmistamattaKpl, calculateDays(selectedWork.ValmistamattaLaskennallisestiH))}
                  <span className="yhteenvetoSmallText">kpl</span>
                </p>

                {data.reward != 0 && (
                  <p className="yhteenvetoText">
                    {(workDeciPercent * (data.reward / calculateDays(selectedWork.ValmistamattaLaskennallisestiH))).toFixed(2)}
                    <span className="yhteenvetoSmallText">€</span>
                  </p>
                )}
              </div>
              <div className="yhteenvetoRow">
                <p className="yhteenvetoTitle">Yhteensä</p>
                <p className="yhteenvetoText">
                  {(workDeciPercent * selectedWork.ValmistamattaMaara).toFixed(1)}
                  <span className="yhteenvetoSmallText">{selectedWork.jmTuote ? "jm" : "m2"}</span>
                </p>

                <p className="yhteenvetoText">
                  {(workDeciPercent * selectedWork.ValmistamattaKpl).toFixed(1)}
                  <span className="yhteenvetoSmallText">kpl</span>
                </p>

                {data.reward != 0 && (
                  <p className="yhteenvetoText">
                    {(workDeciPercent * data.reward).toFixed(1)}0
                    <span className="yhteenvetoSmallText">€</span>
                  </p>
                )}
              </div>
            </div>
          </div>

          <DeleteBtn onClick={() => setVerifyDeleteOpen(true)} />
          <SaveBtn onClick={saveData1} />

        </div>
      </>}
    </>
  );
};
