
import { useEffect, useState } from "react";
import { apiSecure } from "../../../components/api";
import { Loading } from "./../../../components/Loading/Loading";


import { DatePicker, InputNumber, Select } from "antd";
import moment from "moment";
import 'moment/locale/fi';
import dayjs from "dayjs";
import { formatDec } from "../../../components/globalFunctions";


const { Option } = Select;
const { RangePicker } = DatePicker;

export const Screen3 = ({
  setHeaderText,
  closeModal,
  data,
  selectedWork,
  setScreen,
  SaveBtn,
}) => {
  const [isLoading, setisLoading] = useState(true);
  const [holidayReasons, setHolidayReasons] = useState([]);
  const [selectedHoliday, setSelectedHoliday] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [days, setDays] = useState(null);
  const [hours, setHours] = useState(null);

  const [isDayCalc, setIsDayCalc] = useState(null);
  const [isHourCalc, setIsHourCalc] = useState(null);


  useEffect(() => {
    setHeaderText("Lisää poissaolo")
  }, [setHeaderText])

  const getHolidayReasons = () => {
    console.log("getHolidayReasons");
    setisLoading(true);

    let req_config = {
      method: "GET",
      url: `/intra_getHolidayReasons`,
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        setHolidayReasons(response.data.data);
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    getHolidayReasons();
  }, []);

  const saveData1 = () => {
    if (!selectedHoliday) return alert("Valitse Poissaolonsyy");

      if (!startDate) return alert("Valitse aloituspäivämäärä");
      if (!endDate) return alert("Valitse lopetuspäivämäärä");

      if(isDayCalc) {
        if (!days) return alert("Päivien laskennassa virhe");
      }
    setisLoading(true);

    let req_config = {
      method: "POST",
      url: `/intra_newHoliday`,
      data: {
        worker: data.userId,
        holiday: selectedHoliday,
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
        days: days,
        hours: hours,
      },
    };

    console.log(req_config);

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        //setUsersData(response.data.data);
        setisLoading(false);
        closeModal(true)
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  function getDaysBetweenDates(start, end, dayName) {
    var result = [];
    var days = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };
    var day = days[dayName.toLowerCase().substr(0, 3)];
    // Copy start date
    var current = new Date(start);
    // Shift to next of required days
    current.setDate(current.getDate() + ((day - current.getDay() + 7) % 7));
    // While less than end date, add dates to result array
    while (current < end) {
      result.push(new Date(+current));
      current.setDate(current.getDate() + 7);
    }
    return result;
  }

  //Laskenta
  useEffect(() => {
    if (startDate && endDate && selectedHoliday) {
      console.log("datet löytyy");
      console.log("startDate:", startDate);
      console.log("endDate:", endDate);

      
      let durationDays = moment.duration(endDate.diff(startDate)).asDays()+1
      let sundays = getDaysBetweenDates(moment(startDate), moment(endDate).add(1,'days'), "Sun").length; //Get Sundays
      let saturdays = getDaysBetweenDates(moment(startDate), moment(endDate).add(1,'days'), "Sat").length; //Get Saturdays

      console.log('durationDays',durationDays)
      console.log('sundays',sundays)
      console.log('saturdays',saturdays)

      if (isDayCalc) {
        let durationCalc = (durationDays - sundays).toFixed(0);
        console.log('setDays', durationCalc)
        setDays(durationCalc);
      }

      if (isHourCalc) {
        let durationCalc = durationDays - sundays - saturdays; 
        console.log('setHours', durationCalc*8)
        setHours(durationCalc*8)
      }

      if (isDayCalc && isHourCalc) {
        let durationCalc = durationDays - sundays - saturdays; 

        console.log(durationDays, sundays, saturdays, durationCalc)
        console.log('setDays And setHours', durationCalc, durationCalc*8)
        setDays(durationCalc);
        setHours(durationCalc*8)
      }
    }
  }, [startDate, endDate]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>

      <div className="screen3Cont">
        <div>
          <p className="header1 font-dm-bold">Poissaolon tiedot</p>
        </div>

        <div className="screen3Row">
          <p className="font-dm-medium label">Poissaolonsyy</p>

          <Select
            defaultValue="0"
            style={{ width: 200 }}
            className="picker value"
            onChange={(value, option) => {
              let selected = holidayReasons.reasons[value - 1];
              console.log("selected", selected);

              if (selected) {
                console.log("update selected");
                setSelectedHoliday(selected.PoissaoloSyyNro);
                setIsDayCalc(selected.Paivaperusteinen);
                setIsHourCalc(selected.Tuntiperusteinen);
              } else {
                setIsDayCalc(null);
                setIsHourCalc(null);
              }

              setHours(null)
              setDays(null)
              setStartDate(null)
              setEndDate(null)
            }}
          >
            <Option value="0">Valitse...</Option>
            {holidayReasons.reasons?.map((row, index) => {
              return (
                <Option key={index + 1} value={index + 1}>
                  {row.PoissaoloSyy}
                </Option>
              );
            })}
          </Select>
        </div>

        {selectedHoliday && (
          <>
            <div className="screen3Row">
              <p className="font-dm-medium label">Poissaolon kesto</p>
              {/* <p className="font-dm-medium value">20.9. - 20.10.2022</p> */}
              <RangePicker
                className="rangepicker"
                size="small"
                showWeek={true}
                suffixIcon={null}
                value={[
                  startDate,
                  endDate
                ]}
                format={'DD.MM.YYYY'}
                onChange={(value) => {
                  //console.log("onChange", value, dayjs(value[0]).format("YYYY-MM-DD"), dayjs(value[1]).format("YYYY-MM-DD"));
                  setStartDate(value[0]);
                  setEndDate(value[1]);
                }}
                onOk={() => {
                  console.log("onOk");
                }}
              />
            </div>
          </>
        )}

        {startDate && endDate && isDayCalc && (
          <div className="screen3Row">
            <p className="font-dm-medium label">Kesto päivinä</p>
            <p className="font-dm-medium value">{formatDec(days)} päivää</p>
          </div>
        )}

        {hours && isHourCalc && (
          <div className="screen3Row">
            <p className="font-dm-medium label">Kesto tunteina</p>
            <InputNumber
              className="value"
              style={{ width: 70 }}
              value={hours}
              min={0}
              /*               
                max={1.5}
                step={0.1} 
              */
              formatter={(value) => `${value}h`}
              parser={(value) => value.replace("h", "")}
              onChange={(value) => {
                console.log("change value", value);
                setHours(value);
              }}
            />
          </div>
        )}
      </div>

      <div className="footerButtonContWithSave">
          <button
            className="footerButton font-dm-regular"
            onClick={() => setScreen(5)}
          >
            Lisää muu merkintä
          </button>
        </div>

      <SaveBtn onClick={saveData1} />
    </>
  );
};
