import { useEffect, useState } from "react";
import { apiSecure } from "../../components/api";
import { Loading } from "../../components/Loading/Loading";
import "./Projects.css";

import { MenuOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useTranslate } from "../../components/Translate";
import { useSelector } from "react-redux";
import { getEnvSettings } from "../../config/config";
import { Tooltip } from "antd";
import { useMediaQuery } from "react-responsive";


export const Projects = ({ setToken }) => {
  const [isLoading, setisLoading] = useState(false);
  const [projectsWorking, setProjectsWorking] = useState([])
  const [projectsReady, setProjectsReady] = useState([])
  const [renderArray, setRenderArray] = useState({})

  const isMobile = useMediaQuery({ maxWidth: 500 });


  const [backlog, setBacklog] = useState({})

  const { locale } = useTranslate();
  const navigate = useNavigate();
  const envConfig = getEnvSettings();

  const { selectedFactory } = useSelector(
    (state) => state.currentUser.user
  );

  const [filter, setFilter] = useState(() => {
    const savedState = sessionStorage.getItem('projectFilterState');
    return savedState !== null ? JSON.parse(savedState) : 'tuotanto'; // oletusarvo ''
  });

  const getProjects = () => {
    setisLoading(true);
    console.log("getProjects");

    let req_config = {
      method: envConfig.env === "intra" ? "POST" : "GET",
      url: `${envConfig.apiUrlPrefix}getProjects`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        tehdas: selectedFactory
      },
    };

    apiSecure(req_config)
      .then((response) => {
        const { status } = response?.data

        if (status) {
          if (envConfig.env === "intra") {
            setProjectsReady(response.data.data.valmiit)
            setProjectsWorking(response.data.data.tuotannossa)
            /* setRenderArray(response.data.data.tuotannossa) */
            setBacklog(response.data.data.tilauskanta)
          } else {
            setRenderArray(response.data.data)
          }
        }


        setisLoading(false);

      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    getProjects();
    //console.log("useEffect called");
  }, [selectedFactory]);

  useEffect(() => {
    sessionStorage.setItem('projectFilterState', JSON.stringify(filter));


    if (filter == 'tuotanto') {
      setRenderArray(projectsWorking)
    } else if (filter == 'valmiit') {
      setRenderArray(projectsReady)
    } else {
      setRenderArray('')
    }
  }, [filter, projectsWorking, projectsReady])

  if (isLoading) {
    return <Loading />;
  }


  console.log('TESTIENV', process.env.REACT_APP_ENV)


  const RenderFilter = () => {
    return (
      <p className="projects-filter-select font-dm-regular clickable" onClick={() => {
        if (filter === "tuotanto") {
          setFilter("valmiit")
        } else {
          setFilter("tuotanto")
        }
      }}>
        <MenuOutlined style={{ fontSize: 10 }} />  {filter == "tuotanto" ? locale.production : locale.isready}
      </p>
    )
  }

  return (
    <div className="projects-main" >
      <div className="projects-content">


        <div className="projects-f-header">

          {envConfig.env === "intra" && isMobile &&
            <div className="projects-f-mobile-filter">
              <RenderFilter />
            </div>
          }

          <div className="projects-f-row">
            <p className="projects-title font-dm-medium">{locale.projects}</p>

            {envConfig.env === "intra" && !isMobile &&
              <RenderFilter />
            }

            {envConfig.env === "intra" &&
              <div className="projects-backlog font-dm-bold">
                <Tooltip title={locale.unmanufactured_orders} color={"#353535"}>
                  <p className="projects-backlog-item" style={{ color: '#353535' }}>{backlog?.ValmistamattaTilatuistaYhteensa}</p>
                </Tooltip>

                <Tooltip title={locale.unmanufactured_drawings} color={"#353535"}>
                  <p className="projects-backlog-item" style={{ color: '#2BF263' }}>{backlog?.ValmistamattaPiirustuksistaYhteensa}</p>
                </Tooltip>

                <Tooltip title={locale.in_stock} color={"#353535"} >
                  <p className="projects-backlog-item" style={{ color: '#1620EB' }}>{backlog?.VarastossaYhteensa}</p>
                </Tooltip>
              </div>
            }
          </div>

        </div>

        <div className="projects-projects">
          {renderArray?.length >= 1 ?
            renderArray.map((item, index) => {
              return (

                <Link to={`/project/${item.Tyokohdenro}`} key={item.Tyokohdenro} className="projectItem">

                  <div className="projectRow">
                    <p className="projectNumber">{item.Tyokohdenro}</p>
                    <p className="projectSchedule">{item.Aikataulu}</p>
                  </div>

                  <p className="projectTitle">{item.Tyokohde}</p>
                  <div className="projectDetails">
                    <div className="projectDetailBox detailFirst">
                      <p className="ProjectSmallText">{locale.ordered}</p>
                      <p className="projectText">{item.TilattuKplYht}</p>
                    </div>

                    <div className="projectDetailBox">
                      <p className="ProjectSmallText">{locale.blueprints}</p>
                      <p className="projectText">{item.PiirustuksiaKplYht}</p>
                    </div>

                    <div className="projectDetailBox">
                      <p className="ProjectSmallText">{locale.prepared}</p>
                      <p className="projectText">{item.ValmistettuKplYht}</p>
                    </div>

                    <div className="projectDetailBox">
                      <p className="ProjectSmallText">{locale.booked}</p>
                      <p className="projectText">{item.ToimitettuKplYht}</p>
                    </div>
                  </div>
                  {/*                <div className="projectArrow">
                      <RightOutlined className="arrow" style={{marginLeft:20}}/>
                    </div> */}

                </Link>

                // <p
                //   className="projects-projectname font-itc"
                // onClick={() => {
                //   openProject(item.Tyokohdenro);
                // }}
                // >
                //   {item.Tyokohdenro+' '+item.Tyokohde}
                //   <RightOutlined style={{marginLeft:20}}/>
                // </p>
              );
            })
            :
            <h1 className="noprojects font-dm-medium">{locale.noprojects}</h1>
          }
        </div>
      </div>
    </div>
  );
};

/*
  VANGHA
            return (
              <div key={item.Tyokohdenro} className="homepage-card">

                <div className="homepage-card-inner"  onClick={() => {openProject(item.Tyokohdenro)}}>
                  <p className="homepage-card-header font-itc">{item.Nimi}</p>
                  <p className="homepage-card-projectid font-itc">{item.Tyokohdenro}</p>

                  <ItemValue
                    v1={0} 
                    v2={0} 
                    v3={0} 
                    v4={0} 
                  />
                </div>

              



              </div>

            );


*/
