let languages = {
    'fi' : {
        factory_1: 'Ylitornio',
        factory_2: 'Raahe',
        factory_3: 'Kuhmo',
        finland:'FI',
        sweden:'SE',
        english: "EN",

        slogan:'Parasta Betonista',
        deliveries:'Toimitukset',

        loginerror:'Käyttäjätunnus tai salasana väärin!',
        login: 'Kirjaudu sisään',
        loginbtn: 'Kirjaudu sisään',
        loginbtnprogress: 'Kirjaudutaan...',
        logout: 'Kirjaudu ulos',
        username: 'Käyttäjätunnus',
        password: 'Salasana',
        insertusername: 'Syötä käyttäjätunnus!',
        insertpassword: 'Syötä salasana!',
        termstext: 'Käyttäessäsi palvelua hyväksyt palvelun',
        terms: 'Käyttöehdot',
        and: 'ja',
        privacy: 'Tietosuojakäytännöt.',

        production: 'Tuotanto',
        delivery: 'Toimitukset',
        inst_order: 'Asennusjärjestys',

        deliveryheader: 'Toimitus',


        isready: 'Valmiit',

        projects: 'Projektit',
        contacts: 'Yhteystiedot',

        ordered: 'Tilattu',
        blueprints: 'Suunnitelmia',
        prepared: 'Valmistettu',
        booked: 'Toimitettu',

        schedule: "Aikataulu",

        pdfreport: "PDF raportti",
        etapcontrol: "Etappien hallinta",
        etapcontrol2: "Hallitse etappeja",
        etapcontrolhide: "Piilota etapit",
        etaps: "Etapit",
        etapintroduction: "Luomalla etappeja pystyt järjestämään tilauksesi tuotteet erillisiin etappeihin ja määrittämään etapeille aikataulun sekä etappien sisäisen asennusjärjestyksen.",
        etapadd: "Lisää etappi",
        etapedit: "Etapin muokkaus",
        etapadd: "Etapin lisäys",
        etapbase: "Juuritaso",
        etapeditname: "Nimi",
        etapeditdate: "Valitse toimituksen alku/loppu",
        etapeditremove: "Poista etappi",


        elementgroups: "Elementtiryhmät",


        tunnus:"Tunnus",
        id: "ID",
        pituus: "Pituus",
        korkeus: "Korkeus",
        paksuus:"Paksuus",
        m2: "Brutto m2",
        paino: "Paino",
        piirustus: "Suunnitelma",
        valmistus: "Valmistus",
        toimitus: "Toimitus",
        etap: "Etappi",
        factory: "Tehdas",
        waybill: "Kuormakirja",

        noscheduletext: "Elementtikohtaista aikataulua ei määritetty",

        cancel: "Peruuta",
        save: "Tallenna",

        noprojects: "Projekteja ei löytynyt!",
        nophases: "Ei etappia valittu",

        map: "Kartta",
        search: "Hae",


        contactinformation: "Yhteystiedot",
        worksites: "Työkohteet",
        name: "Nimi",
        company: "Yritys",
        phone: "Puhelin",
        mail: "Email",
        address: "Osoite",

        unmanufactured_orders: "Valmistamatta tilatuista",
        unmanufactured_drawings: "Valmistamatta suunnitelmista",
        in_stock: "Varastossa",

        notes: "Muistiot",
        note_saved: "Tallennettu",
        note_unsaved: "Ei tallennettu",
        note_size: "Muistion koko",
        note_size_error: "Huom! Muistio on liian suuri.",
        note_size_error2: "Tallennus ei onnistu! Muistio on liian suuri.",
        note_header_updated: "Otsikon päivitys onnistui!",
        note_header_update_failed: "Otsikon päivitys epäonnistui!",
        note_updated: "Muistio tallennettu!",
        note_update_failed: "Muistion tallennus epäonnistui!",
        note_save_failed: "Virhe päivityksessä",
        note_participants_updated: "Osallistujat päivitetty!",
        note_participants_update_failed: "Osallistujien päivitys epäonnistui!",
        note_delete_question: "Haluatko poistaa muistion?",
        note_deleted: "Muistio poistettu!",


        deliverys: 'Kuormat',
        deliverys_introduction: "Luomalla kuormia pystyt järjestämään elementit kuormiin",
        deliverys_add: "Lisää kuorma",
        deliverys_control: "Kuormien hallinta",
        deliverys_control2: "Hallitse kuormia",
        deliverys_hide: "Piilota kuormat",

        yes: 'Kyllä',
        no: 'Ei',

        back: "Takaisin",
        pcs: 'kpl'
    },
    'se': {
        factory_1: 'Ylitornio',
        factory_2: 'Raahe',
        factory_3: 'Kuhmo',
        finland:'FI',
        sweden:'SE',
        english: "EN",
    
        slogan: 'Det Bästä av Betong',
        deliveries: 'Leveranser',
    
        loginerror: 'Fel användarnamn eller lösenord!',
        login: 'Logga in',
        loginbtn: 'Logga in',
        loginbtnprogress: 'Loggar in...',
        logout: 'Logga ut',
        username: 'Användarnamn',
        password: 'Lösenord',
        insertusername: 'Ange användarnamn!',
        insertpassword: 'Ange lösenord!',
        termstext: 'Genom att använda tjänsten godkänner du',
        terms: 'Användarvillkoren',
        and: 'och',
        privacy: 'Dataskyddspolicyn.',
    
        production: 'Produktion',
        delivery: 'Leveranser',
        inst_order: 'Montering',
    
        deliveryheader: 'Leverans',
    
        isready: 'Färdiga',
    
        projects: 'Projekter',
        contacts: 'Kontakter',
    
        ordered: 'Beställd',
        blueprints: 'Planer',
        prepared: 'Tillverkad',
        booked: 'Levererad',
    
        schedule: "Tidsplan",
    
        pdfreport: "PDF rapport",
        etapcontrol: "Etappkontroll",
        etapcontrol2: "Kontrollera etapper",
        etapcontrolhide: "Dölj etapper",
        etaps: "Etapper",
        etapintroduction: "Genom att skapa etapper kan du organisera dina produkter i etapper och kontrollera tidplan och montering ordning.",
        etapadd: "Lägg till etapp",
        etapedit: "Redigera etapp",
        etapadd: "Lägg till etapp",
        etapbase: "Rotnivå",
        etapeditname: "Namn",
        etapeditdate: "Välj start/slutdatum för leverans",
        etapeditremove: "Ta bort etapp",
    
        elementgroups: "Elementgrupper",
    
        tunnus: "Littera",
        id: "ID",
        pituus: "Längd",
        korkeus: "Höjd",
        paksuus: "Tjocklek",
        m2: "Brutto m2",
        paino: "Vikt",
        piirustus: "Plan",
        valmistus: "Tillverkning",
        toimitus: "Leverans",
        etap: "Etapp",
        factory: "Fabrik",
        waybill: "Fraktsedel",
    
        noscheduletext: "Inget elementspecifikt tidsplan",
    
        cancel: "Avbryt",
        save: "Spara",

        noprojects: "Inga projekt hittades!",
        nophases: "Nej etapp valt",

        map: "Karta",
        search: "Sök",

        contactinformation: "Kontaktinformation",
        worksites: "Arbetsplatser",
        name: "Namn",
        company: "Företag",
        phone: "Telefon",
        mail: "E-post",
        address: "Adress",

        unmanufactured_orders: "Ej tillverkade beställningar",
        unmanufactured_drawings: "Ej färdigställda planer",
        in_stock: "I lager",

        notes: "Anteckningar",
        note_saved: "Sparad",
        note_unsaved: "Inte sparad",
        note_size: "Anteckningens storlek",
        note_size_error: "Observera! Anteckningen är för stor.",
        note_size_error2: "Sparning misslyckades! Anteckningen är för stor.",
        note_header_updated: "Rubrik uppdaterad!",
        note_header_update_failed: "Rubrikuppdatering misslyckades!",
        note_updated: "Anteckningen uppdaterades!",
        note_update_failed: "Uppdateringen av anteckningen misslyckades!",
        note_save_failed: "Fel vid sparning",
        note_participants_updated: "Deltagarna har uppdaterats!",
        note_participants_update_failed: "Uppdateringen av deltagarna misslyckades!",
        note_delete_question: "Vill du ta bort anteckningen?",
        note_deleted: "Anteckningen har tagits bort!",

        deliverys: 'Lastningar',
        deliverys_introduction: "Genom att skapa lastningar kan du ordna elementen i olika lastningar",
        deliverys_add: "Lägg till lastning",
        deliverys_control: "Lastkontroll",
        deliverys_control2: "Hantera laster",
        deliverys_hide: "Dölj laster",


        yes: "Ja",
        no: "Nej",

        back: "Tillbaka",
        pcs: 'st'
    },
    'en': {
        factory_1: 'Ylitornio',
        factory_2: 'Raahe',
        factory_3: 'Kuhmo',
        finland:'FI',
        sweden:'SE',
        english: "EN",
    
        slogan: 'The Best in Concrete',
        deliveries: 'Deliveries',
    
        loginerror: 'Username or password incorrect!',
        login: 'Log in',
        loginbtn: 'Log in',
        loginbtnprogress: 'Logging in...',
        logout: 'Log out',
        username: 'Username',
        password: 'Password',
        insertusername: 'Enter username!',
        insertpassword: 'Enter password!',
        termstext: 'By using the service, you agree to the',
        terms: 'Terms of Use',
        and: 'and',
        privacy: 'Privacy Policies.',
    
        production: 'Production',
        delivery: 'Deliveries',
        inst_order: 'Erection',
    
        deliveryheader: 'Delivery',
    
        isready: 'Ready',
    
        projects: 'Projects',
        contacts: 'Contacts',
    
        ordered: 'Ordered',
        blueprints: 'Plans',
        prepared: 'Casted',
        booked: 'Delivered',
    
        schedule: "Schedule",
    
        pdfreport: "PDF report",
        etapcontrol: "Phase control",
        etapcontrol2: "Manage phases",
        etapcontrolhide: "Hide phases",
        etaps: "Phases",
        etapintroduction: "By creating phases, you can organize your products into phases and set a schedule and erection order.",
        etapadd: "Add phase",
        etapedit: "Edit phase",
        etapadd: "Add phase",
        etapbase: "Rootlevel",
        etapeditname: "Name",
        etapeditdate: "Select start/end date for delivery",
        etapeditremove: "Remove phase",
    
        elementgroups: "Element groups",
    
        tunnus: "DWG. NO.",
        id: "ID",
        pituus: "Length",
        korkeus: "Height",
        paksuus: "Thickness",
        m2: "Gross m2",
        paino: "Weight",
        piirustus: "Plan",
        valmistus: "Casted",
        toimitus: "Delivery",
        etap: "Phase",
        factory: "Factory",
        waybill: "Waybill",
    
        noscheduletext: "No element-specific schedule was defined",
    
        cancel: "Cancel",
        save: "Save",

        noprojects: "No projects found!",
        nophases: "No phase selected",

        map: "Map",
        search: "Search",

        contactinformation: "Contact Information",
        worksites: "Worksites",
        name: "Name",
        company: "company",
        phone: "Phone",
        mail: "Email",
        address: "Address",

        unmanufactured_orders: "Unmanufactured orders",
        unmanufactured_drawings: "Unmanufactured plans",
        in_stock: "In stock",

        notes: "Notes",
        note_saved: "Saved",
        note_unsaved: "Not saved",
        note_size: "Note size",
        note_size_error: "Attention! The note is too large.",
        note_size_error2: "Saving failed! The note is too large.",
        note_header_updated: "Header update succeeded!",
        note_header_update_failed: "Header update failed!",
        note_updated: "Note update succeeded!",
        note_update_failed: "Note update failed!",
        note_save_failed: "Error in saving",
        note_participants_updated: "Participants updated!",
        note_participants_update_failed: "Participants update failed!",
        note_delete_question: "Do you want to delete the note?",
        note_deleted: "Note deleted!",

        deliverys: 'Deliveries',
        deliverys_introduction: "By creating deliveries, you can organize the elements into different deliveries",
        deliverys_add: "Add delivery",
        deliverys_control: "Load Management",
        deliverys_control2: "Manage loads",
        deliverys_hide: "Hide Loads",

        yes: "Yes",
        no: "No",

        back: "Back",
        pcs: 'pcs'
    }
}

export default languages