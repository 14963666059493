import moment from "moment";
import ReactTooltip from "react-tooltip";

//Välit viivoihin
export const formatLineStart = (date) => {
  let newD = moment(date).hours(1).minutes(0).seconds(0);
  return newD;
};
export const formatLineEnd = (date) => {
  let newD = moment(date).hours(23).minutes(0).seconds(0);
  return newD;
};

export const DrawTooltip = ({ id, type, data = {} }) => {

  let makeCount = null

  if (type == 'work') {
      let ValmistamattaKpl = data.kpl
      let workPercent = data.percent*100

      if (ValmistamattaKpl) {
        let count =  ((workPercent / 100) * ValmistamattaKpl).toFixed(0)   //  (KuormittamattaKpl/ValmistamattaKpl * 100).toFixed(0)
        //console.log('count', workPercent, ValmistamattaKpl, count)
        makeCount = count
      }
  } 

 // console.log('DrawTooltip', data)



  return (
    <ReactTooltip id={id} aria-haspopup="true" className="tooltip-base" >

    <div className="tooltip-container">

      
      {type == "work" && (
        <>
          {data.name && (
            <p className="tooltip-header font-dm-medium">
             {data?.id +' ' +data?.name}
            </p>
          )}

          <div className="tooltip-row">
            {data.elementgroup && (
              <p className="tooltip-data font-dm-regular">
                {data.elementgroup}
              </p>
            )}

            {makeCount && (
              <p className="tooltip-data row font-dm-regular">
                {', '+makeCount+' kpl'}
              </p>
            )}

            {data.percent && (
              <p className="tooltip-data row font-dm-regular">
                {', '+(data.percent*100) +' %'}
              </p>
            )}
          </div>

          {data.table && (
            <p className="tooltip-data font-dm-regular">
              {data.table}
            </p>
          )}

          {data.multiplier && (
            <p className="tooltip-data font-dm-regular">
              {"Henkilökohtainen kerroin " + data.multiplier.toFixed(1)}
            </p>
          )}

          {data.pvmReady && (
            <p className="tooltip-data font-dm-regular">
              {"Arvioitu valmistuminen " + data.pvmReady}
            </p>
          )}
        </>
      )}

      {(type == "project" || type == "project-ended")  && (
        <>
          <p className="tooltip-header font-dm-medium">
            {data?.name}
          </p>

          <p className="tooltip-data font-dm-regular">
            {"Arvioitu valmistuminen " + data?.estimatedEnd}
          </p>
        </>
      )}

      {type == "table" && (
        <>
          <p className="tooltip-header font-dm-medium">
            {data?.name}
          </p>
        </>
      )}



      {type == "vacation" && (
        <>
          <p className="tooltip-header font-dm-medium">
            {data?.name}
          </p>

          <p className="tooltip-data font-dm-regular">
            {data?.startdate + " - " + data?.enddate}
          </p>

          <p className="tooltip-data font-dm-regular">
            {data.durationD && data.durationD + " päivää"}
            {data.durationD && data.durationH && ", "}
            {data.durationH && data.durationH + " h"}
          </p>
        </>
      )}

{type == "holiday" && (
        <>
          <p className="tooltip-header font-dm-medium">
            {data?.name}
          </p>

          <p className="tooltip-data font-dm-regular">
            {data?.date}
          </p>
        </>
      )}

{type == "otherdate" && (
        <>
          <p className="tooltip-header font-dm-medium">
            {data?.name}
          </p>

          <p className="tooltip-data font-dm-regular">
            {data?.startdate} - {data?.enddate}
          </p>
        </>
      )}


{(type == "install_proj" || type == "install") && (
        <>
          <p className="tooltip-header font-dm-medium">
          {data?.name}
          </p>

          <p className="tooltip-data font-dm-regular">
            {data?.text}
          </p>

          <p className="tooltip-data font-dm-regular">
            {data?.date}
          </p>
        </>
      )}

</div>
    </ReactTooltip>
  );
};

export const drawLine = (
  array,
  group,
  title,
  date_start,
  date_end,
  type,
  data = {},
  formatDates = true
) => {
  //console.log('drawLine array', array)
  //console.log('drawLine', group, title)

  array.push({
    id: group + "_" + array.length + 1,
    group: group,
    title:
      title /* + ' ('+(array.length + 1)+')' */ || "Kohdetta ei ilmoitettu",
    className: "item-type-" + type,
    type: type,
    start_time: formatDates ? formatLineStart(date_start) : date_start,
    end_time: formatDates ? formatLineEnd(date_end) : date_end,
    ...data,
    /*     vacationId: type == 'vacation' ? data.vacationId : null,
    workId: type == 'work' ? data.workId : null, */
  });
};

export const calculateUserNextJobTime = () => {};

export const calculateUserPrevJobTime = () => {};
