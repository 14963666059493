import axios from "axios";
import config, { getEnvSettings } from "../config/config";
import { useDispatch } from "react-redux";
import AllActions from "../store/actions";


let isRefreshing = false;

export const api = axios.create({
  baseURL: config.api_url,
  timeout: 5 * 1000,
  headers: {
    "Content-Type": "application/json",
  },
});


export const apiSecure = axios.create({
  baseURL: config.api_url,
  timeout: 15 * 1000,
  headers: {
    "Content-Type": "application/json",
  },
});

//Token mukaan requesteihin
apiSecure.interceptors.request.use(async (req) => {
  const credentials = JSON.parse(localStorage.getItem('USER_AUTH'))

  if (credentials) {
    const token = "Bearer " + credentials.accessToken;
    //console.log('APISECURE TOKEN:', token)
    req.headers.authorization = token;
  }

  return req;
});




// Helper function to refresh token
const refreshAccessToken = async (credentials, nro, dispatch) => {
  console.log('Starting Refreshtoken Process', nro);
  const envConfig = getEnvSettings();

  const reqConfig = {
    method: "POST",
    url: `${envConfig.apiUrlPrefix}refreshtoken`,
    data: { refreshToken: credentials.refreshToken },
  };

  try {
    const response = await api(reqConfig);
    const { accessToken, refreshToken } = response.data;

    await localStorage.setItem('USER_AUTH', JSON.stringify({
      ...credentials,
      accessToken,
      refreshToken,
    }));

    console.log('RefreshTokenApiRequest SUCCESS', nro, JSON.parse(localStorage.getItem('USER_AUTH')));

    return accessToken;
  } catch (error) {
    console.log("RefreshTokenApiRequest ERROR:", nro, error);
    console.log('Logging out user'); // dispatch(AllActions.userActions.logOut());
    dispatch(AllActions.userActions.logOut());
    throw error; // Propagate error to calling function
  } finally {
    isRefreshing = false;
    console.log('Refreshtoken Process Done', nro);
  }
};




export const useAxiosInterceptor = () => {
  const dispatch = useDispatch();

console.log('useAxiosInterceptor')

  // Interceptor to handle token expiration
  apiSecure.interceptors.response.use(
    (res) => res,
    async (err) => {
      const nro = Math.random().toString(26).slice(2);
      const originalConfig = err.config;
  
      console.log('[apiSecure ERROR] DEBUG 1', nro);
      console.log('[apiSecure ERROR] URL', originalConfig.url);
  
      if (isRefreshing) {
        console.log('REFRESHING TOKEN ALREADY ON..', nro);
        return Promise.resolve();
      }
  
      if (err.response && err.response.status === 401 && !originalConfig._retry &&  originalConfig.url !== '/intra_refreshtoken') { // Access Token expired
        originalConfig._retry = true;
        isRefreshing = true;
  
        try {
          const credentials = JSON.parse(localStorage.getItem('USER_AUTH'));
          if (credentials) {
            const newAccessToken = await refreshAccessToken(credentials, nro, dispatch);
            originalConfig.headers.authorization = `Bearer ${newAccessToken}`;
            return apiSecure(originalConfig); // Retry original request with new token
          }
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      }
  
      return Promise.reject(err); // Forward error if not handled
    }
  );
  
  return null;
};


